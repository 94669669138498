import { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster } from "react-hot-toast";
import Button from "../../ui/buttons/Button/Button";
import MessageNotification from "../MessageNotification/MessageNotification";
import getDoctorsFromRecords from "../../services/getDoctorsFromRecords";
import formatPhoneNumber from "../../services/formatPhoneNumber";
import * as appointmentsAPI from "../../api/appointments";
import * as servicesAPI from "../../api/services";
import * as schedulesAPI from "../../api/schedules";
import { useAuth } from "../../hooks/useAuth";
import { createAppointment, updateAppointment } from "../../redux/appointments/operations";
import isSunday from "../../services/isSunday";
import css from "./AppointmentForm.module.css";

const AppointmentForm = ({ type }) => {
    let location = useLocation();
    const doctorName = location.state?.item ? location.state?.item?.Doctor?.name + " " + location.state?.item?.Doctor?.surname : "";
    let initialValues = null;
    if (type === "add") {
        initialValues = {
            date: location.state?.item?.date ?? new Date(),
            time: location.state?.item?.time ?? "",
            doctor: doctorName,
            service: "",
            surname: "",
            name: "",
            patronymic: "",
            phone: "",
            comment: "",
        };
    }
    if (type === "edit") {
        initialValues = {
            date: location.state?.item?.date ?? new Date(),
            time: "",
            doctor: "",
            service: location.state?.item?.Service?.description ?? "",
            surname: location.state?.item?.surname ?? "",
            name: location.state?.item?.name ?? "",
            patronymic: location.state?.item?.patronymic ?? "",
            phone: location.state?.item?.phone?.slice(4) ?? "",
            comment: location.state?.item?.comment ?? "",
        };
    }

    const [freeWorkingTime, setFreeWorkingTime] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [bookedAppointments, setBookedAppointments] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [services, setServices] = useState([]);
    const [isLoadingAppointments, setIsLoadingAppointments] = useState(false);

    const dispatch = useDispatch();
    const { user } = useAuth();

    useEffect(() => {
        if (location.state?.item && type === "add") {
            setSelectedDate(new Date(location.state?.item?.date));
            setAvailableDoctors([location.state?.item?.Doctor]);
            setSelectedDoctor(location.state?.item?.Doctor);
            setFreeWorkingTime([location.state?.item]);
        }
        if (location.state?.item && type === "edit") {
            setSelectedDate(new Date(location.state?.item?.date));
        }
    }, [location.state?.item, type]);

    useEffect(() => {
        if (!selectedDate) {
            return;
        }

        const getSchedulesByDate = async (date) => {
            try {
                const response = await schedulesAPI.apiGetSchedules({ date: date.toISOString().slice(0, 10) });

                if (response?.data?.length > 0) {
                    setSchedules(response?.data);
                } else {
                    MessageNotification("error", "На обрану дату не заповнений графік прийому пацієнтів");
                }
            } catch (error) {
                const errorMessage = "Помилка при завантаженні графіку за дату :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification(error, errorMessage);
            }
        };
        const getAppointmentsByDate = async (date) => {
            try {
                const appointments = await appointmentsAPI.apiGetAppointments({ date: date.toISOString().slice(0, 10) });
                setBookedAppointments(appointments?.data);
            } catch (error) {
                const errorMessage = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification(error, errorMessage);
            }
        };

        if (!selectedDoctor) {
            getSchedulesByDate(selectedDate);
        }

        getAppointmentsByDate(selectedDate);
        getServices();
    }, [selectedDate, selectedDoctor]);

    useEffect(() => {
        if (schedules?.length > 0) {
            const res = getDoctorsFromRecords(schedules);
            setAvailableDoctors(res);
        }
    }, [schedules]);

    const handleSubmit=async(values, { resetForm })=>{
        let message;
        if (values.time === "") {
            MessageNotification("error", `Оберіть час запису`);
        } else if (values.service === "") {
            MessageNotification("error", `Оберіть потрібну послугу з переліку`);
        }
        else{         
            const serviceForm=services.find(service => service.description===values.service);
            const queryDate= location.state?.item?.date ? location.state?.item?.date : values.date.toISOString().slice(0,10);
            const queryPhone = values.phone ? "+38 "+values.phone : "";
            let query=null;
            if(type==="add"){
                query={
                    ...values,
                    date: queryDate,
                    phone: queryPhone,
                    doctor_id: selectedDoctor.doctor_id,
                    service_id: serviceForm.service_id,
                    user_id: user.user_id,
                };
            }

            if (type === "edit") {
                query = {
                    ...values,
                    date: queryDate,
                    phone: queryPhone,
                    doctor_id: selectedDoctor.doctor_id,
                    service_id: serviceForm.service_id,
                    user_id: user.user_id,
                };
            }

            delete query.doctor;
            delete query.service;

            for (const key in query) {
                if (query[key] === "") {
                    delete query[key];
                }
            }       
           
            try{
                if(type==="add"){
                    //ВСТАВИТЬ ПРОВЕРКУ НА ДУБЛЯЖ
                    await dispatch(createAppointment(query))
                    .then((res)=>{
                        if(res.payload.status===201){
                            message=`Прийом додано`;
                            MessageNotification("success", message);

                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);

                    });

                }                
                if(type==="edit"){
                    //ВСТАВИТЬ ПРОВЕРКУ НА ДУБЛЯЖ
                    const id = location.state?.item.appointment_id;
                    await dispatch(updateAppointment({query, id }))
                    .then((res)=>{
                        if(res.payload.status===200){
                            message=`Зміни внесені`;
                            MessageNotification("success", message);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`
                        MessageNotification("error", message);

                    });
        
                }
            } catch (error) {
                message = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", message);
            }
            handleCancel(resetForm);
        }
    };

    const handleCancel = (resetForm) => {
        setSelectedDate(null);
        setFreeWorkingTime([]);
        setBookedAppointments([]);
        setSchedules([]);
        setSelectedDoctor(null);
        setAvailableDoctors([]);
        setServices([]);
        setIsLoadingAppointments(false);     
        resetForm();   
        location.state={};

    }

    //если это не воскресенье, то выбранная в календаре дата отрисовывается в input
    const handleDateChange = (date, setFieldValue) => {
        if (!isSunday(date)) {
            setFieldValue("date", date);
            setSelectedDate(date);
        }
    };

    const getServices = async () => {
        try {
            const services = await servicesAPI.apiGetServices();
            const activeServices = services?.data?.filter((service) => service.active);
            setServices(activeServices);
        } catch (error) {
            const errorMessage = "Помилка при завантаженні послуг :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
            MessageNotification("error", errorMessage);
        }
    };

    const handlePhoneInput = (e, setFieldValue) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setFieldValue("phone", formattedPhoneNumber);
    };

    const handleDoctorChange = async (e) => {
        if (!(location.state?.item && type === "add")) {
            const selectedDoc = e.target.defaultValue;

            const choosedDocObject = availableDoctors.find((doc) => selectedDoc === `${doc.name} ${doc.surname}`);
            setSelectedDoctor(choosedDocObject);

            const choosedDocSchedule = schedules.filter((schedule) => schedule.doctor_id === choosedDocObject.doctor_id);

            const freeWorkingTimeForDoctor = [];

            choosedDocSchedule.forEach(function (schedule, index) {
                const condition = bookedAppointments?.find((bookedAppointment) => {
                    const result = schedule.doctor_id === bookedAppointment.doctor_id && schedule.time === bookedAppointment.time;
                    return result;
                });

                if (!condition) {
                    freeWorkingTimeForDoctor.push(schedule);
                }
                if (type === "edit") {
                    if (schedule.doctor_id === location.state?.item.doctor_id && schedule.time === location.state?.item.time) {
                        freeWorkingTimeForDoctor.push(schedule);
                    }
                }
            });
            setFreeWorkingTime(freeWorkingTimeForDoctor);
        }

        setIsLoadingAppointments(true);
    };

    return (
        <div className="container">
            <h3 className={css.formTitle}>Форма запису на прийом</h3>
            <Toaster />
            <Formik initialValues={initialValues} onSubmit={handleSubmit} isValidating>
                {({ values, setFieldValue, resetForm }) => (
                    <Form>
                        <label htmlFor="date" className={css.formLabel}>
                            <p className={css.calendarText}>
                                Оберіть дату прийому<span className={css.accent}>*</span>
                            </p>
                        </label>
                        <Field name="date">
                            {
                                ({field}) => {
                                    const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
                                        <button type="button" className={className} onClick={onClick} ref={ref} disabled={isLoadingAppointments || location.state?.item?.date}>
                                            {value}
                                        </button>
                                    ));
                                    return (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(date) => handleDateChange(date, setFieldValue)}
                                            customInput={<ExampleCustomInput className="example-custom-input" />}
                                            shouldCloseOnSelect={true}
                                            locale="uk"
                                            dateFormat="dd MMMM yyyy"
                                            minDate={new Date()}
                                            // воскресеньям добавляется класс стилей sunday
                                            dayClassName={(date) => (isSunday(date) ? "sunday" : undefined)}
                                        />
                                    );
                                }
                            }
                        </Field>

                        {/* выводится список докторов из графика на выбранную дату*/}
                        {
                            selectedDate && availableDoctors?.length > 0 && (
                                <>
                                    <div className={css.line}></div>
                                    <div id="my-radio-group">
                                        Оберіть лікаря<span className={css.accent}>*</span>
                                    </div>
                                    <div role="group" aria-labelledby="my-radio-group" className={css.doctorsWrapper}>
                                        {availableDoctors.map(({ doctor_id, name, surname }) => (
                                            <label className={css.customRadio} key={`doctor${doctor_id}`}>
                                                <Field type="radio" name="doctor" value={`${name} ${surname}`} onClick={(doctor) => handleDoctorChange(doctor)} />
                                                <span>{`${name} ${surname}`}</span>
                                            </label>
                                        ))}
                                    </div>
                                </>
                            )
    
                        }

                        {freeWorkingTime?.length > 0 && (
                            <>
                                <div className={css.line}></div>
                                <p className={css.timeHeader}>
                                    Оберіть час прийому<span className={css.accent}>*</span>
                                </p>
                                <ul className={css.timeWrapper}>
                                    {freeWorkingTime?.map(({ schedule_id, time }) => {
                                        return (
                                            <li key={schedule_id}>
                                                <Field type="radio" id={`time${schedule_id}`} name="time" value={time} className={css.timeInput} />
                                                <label htmlFor={`time${schedule_id}`} className={css.timeLabel}>
                                                    {time.slice(0, 5)}
                                                </label>
                                            </li>
                                        );
                                    })}
                                </ul>

                                <div className={css.line}></div>
                                <label htmlFor="service" className={css.formLabel}>
                                    Послуга<span className={css.accent}>*</span>
                                </label>
                                <Field as="select" name="service" id="service" className={css.formSelect}>
                                    <option value="" disabled>
                                        Оберіть послугу
                                    </option>
                                    {services?.length > 0 &&
                                        services.map(({ service_id, description }) => (
                                            <option key={service_id} value={description} className={css.formOption}>
                                                {description}
                                            </option>
                                        ))}
                                </Field>

                                <label htmlFor="surname" className={css.formLabel}>
                                    Введіть прізвище пацієнта
                                    {/* <span className={css.accent}>*</span> */}
                                    <Field
                                        type="text"
                                        name="surname"
                                        id="surname"
                                        placeholder="Прізвище пацієнта"
                                        pattern="[A-Za-zА-Яа-яІіЇїЄєЁё'\-]{2,70}"
                                        title="Прізвище повинно містити тільки літери, '-', апостроф ', довжина імені від 2 до 70 символів"
                                        // required
                                        className={css.formInput}
                                    />
                                </label>
                                <label htmlFor="name" className={css.formLabel}>
                                    Введіть ім'я пацієнта
                                    {/* <span className={css.accent}>*</span> */}
                                    <Field
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Ім'я пацієнта"
                                        pattern="[A-Za-zА-Яа-яІіїЇЄєЁё'\-]{2,50}"
                                        title="Ім'я повинно містити тільки літери та  апостроф ', довжина імені від 2 до 50 символів"
                                        // required
                                        className={css.formInput}
                                    />
                                </label>
                                <label htmlFor="patronymic" className={css.formLabel}>
                                    Введіть ім'я по-батькові пацієнта
                                    <Field
                                        type="text"
                                        name="patronymic"
                                        id="patronymic"
                                        placeholder="Ім'я по-батькові пацієнта"
                                        pattern="[A-Za-zА-Яа-яІіїЇЄєЁё'\-]{2,50}"
                                        title="Ім'я по-батькові повинно містити тільки літери та  апостроф ', довжина від 2 до 50 символів"
                                        className={css.formInput}
                                    />
                                </label>
                                <label htmlFor="phone" className={css.formLabel}>
                                    Введіть номер телефону пацієнта
                                    {/* <span className={css.accent}>*</span> */}
                                    <div className={css.phoneInputWrapper}>
                                        <p className={css.phoneCodeText}>+38</p>
                                        <Field
                                            type="phone"
                                            name="phone"
                                            id="phone"
                                            placeholder="Номер телефону пацієнта"
                                            // required
                                            pattern="\(0\d{2}\) \d{3}-\d{2}-\d{2}"
                                            title="Телефонний номер повинен починатися з '+380' та мати 12 цифр"
                                            onChange={(e) => handlePhoneInput(e, setFieldValue)}
                                            className={css.formPhoneInput}
                                        />
                                    </div>
                                </label>
                                <label htmlFor="comment" className={css.formLabel}>
                                    Введіть коментар
                                    <Field type="text" name="comment" id="comment" placeholder="Коментар" className={css.formTextarea} as="textarea" />
                                </label>
                                <div className={css.butWrapper}>
                                    <Button type="submit">Записати на прийом</Button>
                                    <Button type="button" onClick={() => handleCancel(resetForm)}>
                                        Відміна
                                    </Button>
                                </div>
                            </>
                        )}
                        {isLoadingAppointments && freeWorkingTime?.length === 0 && (
                            <>
                                <p className={css.timeInfo}>Немає вільних місць на обрану дату</p>
                                <Button type="button" onClick={() => handleCancel(resetForm)}>
                                    Відміна
                                </Button>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AppointmentForm;
