const isRecord = (item, key, array)=>{
    console.log("isRecord array=", array)
    console.log("isRecord item=", item)
    console.log("isRecord key=", key)
    let stringId=key.slice(0,key.length-1) +"_id";
    if(key==="workhours"){
        stringId="time";
    }

    const isRec = array?.some(object=>object[stringId]===item[stringId]);
    console.log("isRecord stringId=", stringId)
    console.log("isRecord isRec=", isRec)

    return isRec;
}

export default isRecord;