export const baseURL = "https://calendar.eglamed.com.ua"; //** !!!  для ХОСТИНГУ !!! */
// export const baseURL = "http://localhost:4000"; //** для локалхосту */
// export const baseURL = "http://192.168.128.101:4000"; //** для WAN на локалхості (mobile devices)*/

export const titles = {
    time: "Час прийому",
    active: "Статус активності",
    description: "Опис послуги",
    price: "Ціна",
    name: "Ім'я",
    surname: "Прізвище",
};
