import { Suspense, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import MessageNotification from "../../components/MessageNotification/MessageNotification";
import CalendarToolbar from "../../components/CalendarToolbar/CalendarToolbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSchedule } from "../../hooks/useSchedule";
import { getSchedules } from "../../redux/schedules/schedulesOperations";
import Loader from "../../ui/Loader/Loader";

const SchedulePage = () => {
    const navigate = useNavigate();
    const { scheduleMonth, scheduleWeek, scheduleDay, scheduleYear } = useSchedule();
    const dispatch = useDispatch();

    const { pathname } = useLocation();
    const initialPeriodType = pathname.includes("/schedule/day") ? "day" : pathname.includes("/schedule/week") ? "week" : "month";
    const [periodType, setPeriodType] = useState(initialPeriodType || "month");
    const [currentDateMonth] = useState(scheduleMonth);
    const [currentDateWeek] = useState(scheduleWeek);
    const [currentDateYear] = useState(scheduleYear);

    useEffect(() => {
        const fetchData = async () => {
            let message;
            try {
                if (periodType === "month") {
                    await dispatch(getSchedules("month"))
                    .then(res=>{
                        console.log("periodType === month res=", res)
                        if(res.payload.status===200){
                            navigate(`/schedule/month/${scheduleMonth}`);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);

                    });
                
                }
                if (periodType === "week") {
                    await dispatch(getSchedules("week"))
                    .then(res=>{
                        console.log("periodType === week res=", res)
                        if(res.payload.status===200){
                            navigate(`/schedule/week/${currentDateWeek}`);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);
                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);
                    });
                }
                
                if (periodType === "day") {
                    await dispatch(getSchedules("day"))
                    .then(res=>{
                        console.log("periodType === day res=", res)
                        if(res.payload.status===200){
                            navigate(`/schedule/day/${scheduleDay}`);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);

                    });
                }
            }catch (error) {
                message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                MessageNotification("error", message);
            }
        }

        fetchData();
    }, [navigate, periodType, scheduleMonth, currentDateWeek, scheduleDay, dispatch]);

    const checkDate = (date) => {
        const currentDate = new Date(currentDateYear, currentDateMonth, 2);
        if (date > currentDate) {
            return false;
        }
        return true;
    };

    return (
        <>
            <Toaster/>
            <h2 className="caption">
                <center>Розклад</center>
            </h2>
            <CalendarToolbar periodType={periodType} changePeriod={setPeriodType} checkDate={checkDate} page={"schedule"} />
            <Suspense fallback={<Loader type={"suspense"} />}>
                <Outlet context={[setPeriodType, checkDate]} />
            </Suspense>
        </>
    );
};

export default SchedulePage;
