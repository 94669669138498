import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { apiGetSchedules, apiAddSchedule, apiDeleteSchedule } from "../../api/schedules";

import getDateEnd from "../../services/getDateEnd";

import { startOfWeek, addDays, format } from "date-fns";

export const getSchedules = createAsyncThunk("schedules/get", async (period, thunkAPI) => {
    const {
        schedules: { scheduleDay, scheduleMonth, scheduleYear },
    } = thunkAPI.getState();

    const fullMonth = (scheduleMonth + 1).toString().padStart(2, "0");
    const dateStart = `${scheduleYear}-${fullMonth}-01`;
    const dateEnd = getDateEnd(scheduleMonth, scheduleYear);
    const fullDay = scheduleDay.toString().padStart(2, "0");

    let paramsString = {};
    if (period === "month") {
        paramsString = {
            ds: dateStart,
            de: dateEnd,
        };
    }
    //** begin week */
    if (period === "week") {
        function getMondayAndSaturday(date) {
            // Визначаємо дату понеділка
            const monday = startOfWeek(date, { weekStartsOn: 1 }); // Початок тижня (понеділок)

            // Додаємо 5 днів до понеділка, щоб отримати суботу
            const saturday = addDays(monday, 5);

            return { monday, saturday };
        }
        const date = `${scheduleYear}-${fullMonth}-${scheduleDay}`;
        const { monday, saturday } = getMondayAndSaturday(date);
        paramsString = {
            ds: format(monday, "yyyy-MM-dd"),
            de: format(saturday, "yyyy-MM-dd"),
        };
    }
    //** end week */

    if (period === "day") {
        paramsString = {
            date: `${scheduleYear}-${fullMonth}-${fullDay}`,
        };
    }

    console.log(paramsString);

    try {
        const response = await apiGetSchedules(paramsString);
        return {data: response.data, status: response.status};
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const createSchedule = createAsyncThunk("schedules/create", async (schedule, thunkAPI) => {
    try {
        const response = await apiAddSchedule(schedule);
        return {data: response.data, status: response.status};
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const deleteSchedule = createAsyncThunk("schedules/delete", async (id, thunkAPI) => {
    try {
        const response = await apiDeleteSchedule(id);
        return {data: response.data, status: response.status};
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});
