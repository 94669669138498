import Button from "../Button/Button";
import { FiLogIn } from "react-icons/fi";

const ButLogin = ({type}) => {
    return (
        <Button type={type}>
            Увійти <FiLogIn />
        </Button>
    );
};

export default ButLogin;
